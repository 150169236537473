import React, { Component } from 'react';
import Modal from "../Generics/Modal";
import config from '../../../config';
import swal from 'sweetalert';

// Import React FilePond
import { FilePond } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

//
import { addCommunity, editCommunity } from '../../webServices'

class AddCommunityModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            community: null,
            validInput: false,
            inputName: "",
            inputName_has_changed:false,
            files: [],
            image_has_changed: false,
            processing: false
        };

        this.callback = null;
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.handleNameChanged = this.handleNameChanged.bind(this);
        this.handleModifFile = this.handleModifFile.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    handleNameChanged(e) {
        const value = e.target.value;
        this.setState({
            inputName: value,
            inputName_has_changed: true,
            validInput: value.trim().length > 0
        });
    }

    show(community = null, callback=null) {
        this.callback = callback;
        this.setState({
            show: true,
            community: community,
            validInput: community && community.name !== "",
            inputName: community ? community.name : "",
            inputName_has_changed: community ? community.name : false,
            files: community ? config.server + "" + community.image_url : null,
            image_has_changed: false
        });
    }

    hide() {
        this.setState({ show: false, community: null, validInput: false, inputName: "" });
    }

    handleModifFile(fileItems) {
        this.setState({
            files: fileItems.map((fileItem) => fileItem.file),
            // eslint-disable-next-line eqeqeq
            image_has_changed: this.state.image_has_changed || !this.state.files || this.state.files.length == 0
        });
    }

    validateForm(e) {
        e.preventDefault();
        this.setState({processing: true});

        let image = null;
        if (this.state.files && this.state.image_has_changed && this.state.files[0]) {
            image = this.state.files[0] || null;
        }
        
        if (this.state.community) {
            editCommunity(this.state.community.pk, e.target.name.value, image)
            .then(res => {
                //hide modal after Posting Alert
                this.hide();
                e.target.reset();
                this.setState({processing: false});
                if (typeof this.callback === "function") {
                    this.callback(res.item);   
                } else {
                    window.location.reload();
                }
            })
            .catch(error => {
                this.setState({processing: false});
                swal({
                    icon: "error",
                    title: "Une erreur est survenue",
                    text: "Impossible de modifier la communauté."
                });
            });
        }

        else {
            addCommunity(e.target.name.value, image)
            .then(res => {
                //hide modal after Posting Alert
                this.hide();
                e.target.reset();
                this.setState({processing: false});
                if (typeof this.callback === "function") {
                    this.callback(res.item);   
                } else {
                    window.location.reload();
                }
            })
            .catch(error => {
                this.setState({processing: false});
                swal({
                    icon: "error",
                    title: "Une erreur est survenue",
                    text: "Impossible de créer la communauté."
                });
            });
        }
        
    }

    render() {
        return (
            <Modal show={this.state.show} handleClose={this.hide} title={this.state.community ? `Modifier la communauté: ${this.state.community.name} ` : "Ajouter une communauté"}>
                <div className="card-inner">
                    <form onSubmit={this.validateForm} id="add-community-form" className='popupForm'>

                        <div className="form-group">
                            <div className="form-control-wrap">
                                <label className="form-label labells"> Nom de la communauté  </label>
                                <input type="text" id="name" name="name" className={`form-control form-control-xl form-control-outlined ${this.state.inputName_has_changed && this.state.inputName.trim().length === 0 ? 'is-invalid':''} `} onChange={this.handleNameChanged} value={this.state.inputName} required />
                                <small className={'invalid-feedback'}> Nom de la communauté obligatoire </small>
                            </div>
                        </div>

                        <div className="form-group">
                            <FilePond
                                ref={(ref) => (this.pond = ref)}
                                files={this.state.files}
                                allowMultiple={false}
                                maxFiles={1}
                                onupdatefiles={this.handleModifFile}
                                name="image"
                                labelIdle='Faites glisser vos fichiers ou <span class= "filepond--label-action"> Parcourir <span>'
                            />
                        </div>

                        <div className="form-group">
                            <button type="submit" className="btn btn-lg btn-success bg-gradient " disabled={this.state.processing || !this.state.validInput}  > {this.state.community ? "Modifier" : "Ajouter"} </button>
                        </div>

                    </form>
                </div>
            </Modal>
        );
    }
}

export default AddCommunityModal;