import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../img/logo-alerte-70-header.png';
import { ImPlus } from 'react-icons/im';

import Mainheader from '../../Header';

import ListCommunities from './ListCommunities';
import ListCommunityMembers from './ListCommunityMembers';
import AddCommunityModal from './AddCommunityModal';
import SendCommunityEmailReminderModal from './SendCommunityEmailReminderModal';
import MemberEditModal from './MemberEditModal';
import MemberDisableModal from './MemberDisableModal';
import { getUserPermissions } from "../../webServices"

class Communities extends Component {
    constructor(props) {
        super(props);

        this.editCommunityModalRef = React.createRef();
        this.sendCommunityEmailReminderModalRef = React.createRef();
        this.MemberEditModalRef = React.createRef();
        this.disableMemberModalRef = React.createRef();

        this.addCommunity = this.addCommunity.bind(this);
        this.addMember = this.addMember.bind(this);
        this.editCommunity = this.editCommunity.bind(this);
        this.sendCommunityEmailReminder = this.sendCommunityEmailReminder.bind(this);
        this.archiveCommunity = this.archiveCommunity.bind(this);
        this.editMember = this.editMember.bind(this);
        this.disableMember = this.disableMember.bind(this);
        this.getListCommunitiesPage = this.getListCommunitiesPage.bind(this);
        this.goToCommunityList = this.goToCommunityList.bind(this);
        this.getListCommunitiesMemberPage = this.getListCommunitiesMemberPage.bind(this);
        this.goToCommunityMemberList = this.goToCommunityMemberList.bind(this);

        this.state = { current_page: this.getListCommunitiesPage() };
    }

    async componentDidMount(id) {
        const permissions = await getUserPermissions();
    
        this.setState({
          can_add_community: permissions != undefined && permissions.includes("restapi.add_community"),
        });
    }

    addCommunity() {
        this.editCommunityModalRef.current.show();
    }

    editCommunity(community, callback) {
        this.editCommunityModalRef.current.show(community, callback);
    }

    sendCommunityEmailReminder(community, callback) {
        this.sendCommunityEmailReminderModalRef.current.show(community, callback);
    }

    editMember(member, callback) {
        this.MemberEditModalRef.current.show(member, null, null, callback);
    }

    disableMember(member, callback) {
        this.disableMemberModalRef.current.show(member, callback);
    }

    addMember(community_pk, community_name, callback) {
        this.MemberEditModalRef.current.show(null, community_pk, community_name, callback);
    }

    archiveCommunity(community) {
        //TODO
    }

    getListCommunitiesPage() {
        return <ListCommunities goToCommunityMember={this.goToCommunityMemberList} editCommunity={this.editCommunity} sendCommunityEmailReminder={this.sendCommunityEmailReminder} archiveCommunity={this.archiveCommunity} />
    }

    goToCommunityList() {
        this.setState({
            current_page: this.getListCommunitiesPage()
        });
    }

    getListCommunitiesMemberPage(community_pk, community_name) {
        return <ListCommunityMembers community_pk={community_pk} community_name={community_name} goToCommunityList={this.goToCommunityList} editMember={this.editMember} disableMember={this.disableMember} addMember={this.addMember}/>
    }

    goToCommunityMemberList(community_pk, community_name) {
        this.setState({
            current_page: this.getListCommunitiesMemberPage(community_pk, community_name)
        });
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true
        }
    }

    render() {
        const { can_add_community } = this.state;

        return (
            <div>
                <div className="nk-app-root">
                    <div className="nk-main">
                        <div className="nk-sidebar nk-sidebar-fixed is-light " data-content="sidebarMenu">
                            <div className="nk-sidebar-element nk-sidebar-head">

                                <div className="nk-menu-trigger">
                                    <Link to="/" className="nk-nav-toggle nk-quick-nav-icon d-xl-none" data-target="sidebarMenu"><em className="icon ni ni-arrow-left" /></Link>
                                    <Link to="/" className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex" data-target="sidebarMenu"><em className="icon ni ni-menu" /></Link>
                                </div>

                                <div className="nk-sidebar-brand">
                                    <Link to="/" className="logo-link nk-sidebar-logo">
                                        <img className="logo-light logo-img" src={logo} srcSet={logo} width={150} alt="logo" />
                                        <img className="logo-dark logo-img" src={logo} srcSet={logo} width={150} alt="logo-dark" />
                                    </Link>
                                </div>

                            </div>
                            {/* <!-- .nk-sidebar-element --> */}
                            <div className="nk-sidebar-element nk-sidebar-body">
                                <div className="nk-sidebar-content">
                                    <div className="nk-sidebar-menu" data-simplebar>
                                        <div className="nk-menu">
                                            <div className='btn-menu'>
                                                { can_add_community ? <div className="btn btn-primary import_file" type="button" onClick={() => { this.addCommunity() }}> <ImPlus /> &nbsp; Ajouter une communauté </div> : null }
                                            </div>

                                        </div>
                                        {/* <!-- .nk-menu --> */}
                                    </div>
                                    {/* <!-- .nk-sidebar-menu --> */}
                                </div>
                                {/* <!-- .nk-sidebar-content --> */}
                            </div>
                            {/* <!-- .nk-sidebar-element --> */}

                        </div>

                        <div className="nk-wrap">
                            <Mainheader />
                            <div className="nk-content h-100">
                                <div className="container-fluid">
                                    <div className="nk-content-inner">
                                        <div className="nk-content-body">
                                            <div className="nk-block">
                                                <div className="row g-gs">

                                                    <div className="col-md-4 marginn">
                                                        <h4 className="history"> Communautés </h4>
                                                    </div>

                                                    {this.state.current_page}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AddCommunityModal ref={this.editCommunityModalRef} />
                <SendCommunityEmailReminderModal ref={this.sendCommunityEmailReminderModalRef} />
                <MemberEditModal ref={this.MemberEditModalRef} />
                <MemberDisableModal ref={this.disableMemberModalRef} />
            </div>
        );
    }
}

export default Communities;