import React, { Component } from 'react';
import Modal from "../Generics/Modal";
import swal from 'sweetalert';
import { addCategory, editCategory } from '../../webServices'

class AddCategoryModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            category: null,
            validInput: false,
            inputName: "",
            inputName_has_changed: false,
            processing: false
        };

        this.callback = null;
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.handleNameChanged = this.handleNameChanged.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    handleNameChanged(e) {
        const value = e.target.value;
        this.setState({
            inputName: value,
            inputName_has_changed: true,
            validInput: value.trim().length > 0
        });
    }

    show(category = null, callback = null) {
        this.callback = callback;
        this.setState({
            show: true,
            category: category,
            validInput: category && category.name !== "",
            inputName: category ? category.name : "",
            inputName_has_changed: category ? category.name : false
        });
    }

    hide() {
        this.setState({ show: false, category: null, validInput: false, inputName: "" });
    }

    validateForm(e) {
        this.setState({ processing: true });
        e.preventDefault();

        if (this.state.category) {
            editCategory(this.state.category.pk, e.target.name.value)
            .then(res => {
                this.hide();
                e.target.reset();
                this.setState({processing: false});
                if (typeof this.callback === "function") {
                    this.callback(res.item);   
                } else {
                    window.location.reload();
                }
            })
            .catch(error => {
                this.setState({processing: false});
                swal({
                    icon: "error",
                    title: "Une erreur est survenue",
                    text: "Impossible de modifier la catégorie."
                });
            });
        } 

        else {
            addCategory(e.target.name.value)
            .then(res => {
                this.hide();
                e.target.reset();
                this.setState({processing: false});
                if (typeof this.callback === "function") {
                    this.callback(res.item);   
                } else {
                    window.location.reload();
                }
            })
            .catch(error => {
                this.setState({processing: false});
                swal({
                    icon: "error",
                    title: "Une erreur est survenue",
                    text: "Impossible de créer la catégorie."
                });
            });
        }
    }

    render() {
        //const enableCsvFields = this.state.file_ext === 'csv';
        return (
            <Modal show={this.state.show} handleClose={this.hide} title={this.state.category ? `Modifier la catégorie:  ${this.state.category.name} ` : "Ajouter une catégorie"}>
                <div className="card-inner">
                    <form onSubmit={this.validateForm} id="add-category-form" className='popupForm'>  
                        <div className="form-group">
                            <div className="form-control-wrap">
                                <label className="form-label labells"> Nom de la catégorie  </label>
                                <input type="text" id="name" name="name" onChange={this.handleNameChanged} className={`form-control form-control-xl form-control-outlined ${this.state.inputName_has_changed && this.state.inputName.trim().length === 0 ? 'is-invalid':''}`} value={this.state.inputName} required />
                                <small className={`invalid-feedback`}> Nom de la categorie obligatoire </small>
                            </div>
                        </div>

                        <div className="form-group">
                            <button type="submit" className="btn btn-lg btn-success" disabled={this.state.processing || !this.state.validInput}  >{this.state.category ? "Modifier" : "Ajouter"} </button>
                        </div>
                    </form>
                </div>
            </Modal>
        );
    }
}

export default AddCategoryModal;