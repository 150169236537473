import axios from 'axios';
import config from '../config';
//import swal from 'sweetalert';

let username = null;
let permissions = null;
let user_limitations = null;
let communities = null;
let cities = null;
let categories = null;
//let controller = null;
//let ws = null;

let permissionsTimestamp = -1;
let citiesTimestamp = -1;
let communitiesTimestamp = -1;
let categoriesTimestamp = -1;

function logout() {
    localStorage.removeItem('auth');
    window.location.reload();
}

/* eslint-disable no-unused-vars */
function requestException(status, message) {
    this.status = status;
    this.message = message;
 }

//TODO: manage abord controller
//TODO: manage erreur display notif
async function sendRequest(url, method='get', params={}, data=null, headers=null, responseType='json') {
    const isEnvDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

    if (isEnvDevelopment) {
        params['access_token'] = getAccesToken();
    }
    
    try {
        // if (controller) {
        //     controller.abort();
        //     controller = null;
        // }

        // //Abort Controller
        // controller = new AbortController();
        // const signal = controller.signal;

        const response = await axios.request({
            url: url,
            /*signal:signal,*/
            method: method,
            baseURL: config.rest_api_url,
            headers: isEnvDevelopment ? {} : { authorization: `Bearer ${getAccesToken()}` },//TODO: ne fonctionne pas en localhost (probleme cors)...
            params: params,
            data: data,
            responseType: responseType
        });

        if (response && response.status >= 200 && response.status < 300) {
            return response.data;
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {
            logout();
        }
        
        throw error;
    }

    return null;
}

export function getAccesToken() {
    return JSON.parse(localStorage.getItem('auth')).access_token;
}

//Request to get All Communities
export async function getAllCommunities() {
    const delay = new Date().getTime() - communitiesTimestamp;
    if (communities && delay < (60 * 1000)) {
        return communities;
    }

    let result = await getCommunities({'paginate-by': 0});
    communities = result.items;
    communitiesTimestamp = new Date().getTime();
    return communities;
}

export async function getCommunities(params = {}) {
    if (!('enabled' in params)) {
        params['enabled'] = 1;
    }

    const responseData = await sendRequest(`/admin/communities/`, 'get', params);
    return responseData;
}

export async function getCommunitiesSelectOptions(inputValue="", paginate_by = 0) {
    let communities;
    if (inputValue === "" &&  paginate_by === 0) {
        communities = await getAllCommunities();
    } 

    else {
        communities = await getCommunities({'name-startswith': inputValue, 'paginate-by': paginate_by});
        communities = communities.items
    }

    let options = [];
    for (let i in communities) {
        options.push({ value: communities[i].pk, label: communities[i].name });
    }

    return options;
}

export async function getCommunitiesSelectOptionsAsync(inputValue, callback) {
    return await getCommunitiesSelectOptions(inputValue, 20);
}

export async function getCommunityMembers(community_pk, params = {}) {
    params['community__pk'] = community_pk;

    if (!('enabled' in params)) {
        params['enabled'] = 1;
    }

    if (!('person__enabled' in params)) {
        params['person__enabled'] = 1;
    }

    const responseData = await sendRequest(`/admin/members/`, 'get', params)

    return responseData;
}

//Request to get All Cities
export async function getAllCities() {
    const delay = new Date().getTime() - citiesTimestamp;
    if (cities && delay < (60 * 1000)) {
        return cities;
    }

    let result = await getCities({'paginate-by': 0});
    cities = result.items;
    citiesTimestamp = new Date().getTime();
    return cities;
}

export async function getCities(params = {}) {
    const responseData = await sendRequest(`/admin/cities/`, 'get', params)
    return responseData;
}

export async function getCitiesSelectOptions(value="", paginate_by = 0) {
    let cities;

    if (!isNaN(value)) {
        if (value.length === 5) paginate_by = 0
        cities = await getCities({'zip-startswith': value, 'paginate-by': paginate_by});
        cities = cities.items;
    }

     else if (value === "" && paginate_by === 0) {
        cities = await getAllCities();
    }

     else {
        cities = await getCities({'name-startswith': value, 'paginate-by': paginate_by});
        cities = cities.items;
    }

    let options = [];

    for (let i in cities) {
        options.push({ value: cities[i].pk, label: cities[i].name + " (" +  cities[i].zip + ")", data: {coord: cities[i].coord}});
    }
    
    return options;
}

export async function getCitiesSelectOptionsAsync(inputValue, callback) {
    return await getCitiesSelectOptions(inputValue, 20);
}

export async function getAllCategories() {
    const delay = new Date().getTime() - categoriesTimestamp;

    if (categories && delay < (60 * 1000)) {
        return categories;
    }

    let result = await getCategories({'paginate-by': 0});
    categories = result.items;
    categoriesTimestamp = new Date().getTime();

    return categories;
}

export async function getCategories(params = {}) {
    const responseData = await sendRequest(`/admin/categories/`, 'get', params)
    return responseData;
}

export async function getCategoriesSelectOptions(inputValue="", paginate_by = 0 ) {
    let categories;
    if (inputValue === "" && paginate_by === 0) {
        categories = await getAllCategories();
    } 

    else {
        categories = await getCategories({'name-startswith': inputValue, 'paginate-by': paginate_by});
        categories = categories.items
    }

    let options = [];

    for (let i in categories) {
        options.push({ value: categories[i].pk, label: categories[i].name});
    }

    return options;
}

export async function getCategoriesSelectOptionsAsync(inputValue, callback) {
    return await getCategoriesSelectOptions(inputValue, 20);
}

//Request to get All Alerts
export async function getAlerts(params = {}) {
    const responseData = sendRequest(`/admin/alerts/`, 'get', params);
    return responseData;
}

//Request to get the nomber of people affected with the spécified parameters (city, range, whole_dep and communities)
export async function getNbPeopleAffected(data = {}) {
    const responseData = await sendRequest(`/admin/nb-people-affected/`, 'post', {}, data);
    return responseData;
}

//Request to get Actives Alerts
export async function getActiveAlerts(params = {}) {
    params['active'] = 1;
    return getAlerts(params);
}

//Request to get Inactives Alerts
export async function getInactiveAlerts(params = {}) {
    params['active'] = 0;
    return getAlerts(params);
}

export async function addAlert(data) {
    const responseData = await sendRequest(`/admin/alerts/`, 'post', {}, data, {'Content-Type': 'multipart/form-data','Accept': '*/*'});
    return responseData;
}

export async function editAlert(alert_id, data) {
    const responseData = await sendRequest(`/admin/alerts/${alert_id}/`, 'put', {}, data, {'Content-Type': 'multipart/form-data','Accept': '*/*'});
    return responseData;
}

//Request to get readings-stats
export async function getOpeningbyCommunity(alert_id) {
    const responseData = await sendRequest( `/admin/alerts/${alert_id}/readings-stats/`, 'get');
    return responseData;
}

//Request to alert_responses
export async function getAlertResponses(alert_id) {
    const responseData = await sendRequest( `/admin/alerts/${alert_id}/responses/`, 'get');
    return responseData;
}

//Request to get alert_details
export async function getAlertDetails(alert_id) {
    const responseData = await sendRequest( `/admin/alerts/${alert_id}/`, 'get');
    return responseData;
}

//Request to get alert recipients
export async function getAlertRecipients(alert_id) {
    const responseData = await sendRequest( `/admin/alerts/${alert_id}/recipients/`, 'get');
    return responseData;
}

// Request to get alert_responses_by_community
export async function getResponsebycommunity(alert_id, community_id) {
    const responseData = await sendRequest( `/admin/alerts/${alert_id}/responses/`, 'get', {community : community_id});
    return responseData;
}

// Request to get Alert Images
export async function getAlertImage(alert_id) {
    const responseData = await sendRequest( `images/alert/${alert_id}`, 'get', {}, null, null, 'blob');

    if (!responseData) return null;
    
    return URL.createObjectURL(responseData)
}

// Request to get people_affected
export async function getDetailRecipients(alert_id) {
    const responseData = await sendRequest( `/admin/alerts/${alert_id}/recipients/`, 'get');
    return responseData;
}

// Request to close Alert
export async function closeAlert(alert_id, message="") {
    let responseData;
    if (message) {
        var data = new FormData();
        data.append('message', message );
        responseData = await sendRequest( `/admin/alerts/${alert_id}/close/`, 'put', {}, data);
    } else {
        responseData = await sendRequest( `/admin/alerts/${alert_id}/close/`, 'put');
    }
    return responseData;
}

// Request to get chart-response-progress
export async function getChartsResponseProgress(alert_id, range) {
    const responseData = await sendRequest( `/admin/alerts/${alert_id}/responses-progress/?range=${range}`, 'get');
    return responseData;
}

// Request to get global-responses-stats
export async function getGlobalresponsesStats() {
    const responseData = await sendRequest('/admin/stats/responses/', 'get');
    return responseData;
}

async function updateUserAndPermissions(include_user_limitations) {
    const delay = new Date().getTime() - permissionsTimestamp;
    include_user_limitations = typeof include_user_limitations !== "undefined" ? include_user_limitations : false;

    if ((!include_user_limitations || user_limitations) && permissions && username && delay < (60 * 1000)) {
        return ;
    }

    const params = include_user_limitations ? `?include_user_limitations=1` : ``;
    const responseData = await sendRequest(`/admin/auth/permissions/${params}`, 'get');
    username = responseData.username;
    permissions = responseData.permissions;
    user_limitations = responseData.limitations;
    permissionsTimestamp = new Date().getTime();
}

// Request to get user permissions
export async function getUserName() {
    await updateUserAndPermissions();
    return username;
}

// Request to get user permissions
export async function getUserPermissions() {
    await updateUserAndPermissions();
    return permissions;
}

// Request to get user permissions
export async function getUserLimitations() {
    await updateUserAndPermissions(true);
    return user_limitations;
}

export async function getImportedFiles(params = {}) {
    const responseData = await sendRequest('/admin/import/', 'get', params);
    return responseData;
}

export async function getImportedFile(file_id) {
    const responseData = await sendRequest(`/admin/import/${file_id}/`, 'get');
    return responseData;
}

export async function getImportedFilePreview(file_id, params = {}) {
    const responseData = await sendRequest(`/admin/import/${file_id}/preview/`, 'get', params);
    return responseData;
}

export async function updateImportedFileRow(file_id, row_id, data={}) {
    data['pk'] = row_id;
    const responseData = await sendRequest( `/admin/import/${file_id}/update-row/`, 'put', {}, data);
    return responseData;
}

export async function ImportIgnoreRow(file_id, row_id) {
    const responseData = await sendRequest( `/admin/import/${file_id}/ignore-row/`, 'put', {},  {'pk': row_id});
    return responseData;
}

export async function ImportDoNotIgnoreRow(file_id, row_id) {
    const responseData = await sendRequest( `/admin/import/${file_id}/do-not-ignore-row/`, 'put', {},  {'pk': row_id});
    return responseData;
}

export async function isImportedFileReady(file_id) {
    const responseData = await sendRequest(`/admin/import/${file_id}/ready/`, 'get');
    return responseData.ready;
}

export async function doImportFile(file_id) {
    const responseData = await sendRequest(`/admin/import/${file_id}/do-import/`, 'put');
    return responseData;
}

export async function cancelImport(file_id) {
    const responseData = await sendRequest(`/admin/import/${file_id}/`, 'delete');
    return responseData;
}

export async function setColumnMapping(file_id, data) {
    const responseData = await sendRequest(`/admin/import/${file_id}/set-columns-mapping/`, 'put', {}, data);
    return responseData;
}

export async function importFile(data) {
    const responseData = await sendRequest(`/admin/import/`, 'post', {}, data, {'Content-Type': 'multipart/form-data','Accept': '*/*'});
    return responseData;
}

export async function addCategory(category_name) {
    const responseData = await sendRequest(`/admin/categories/`, 'post', {}, {'name': category_name});
    return responseData;
}

export async function editCategory(category_id, category_name) {
    const responseData = await sendRequest(`/admin/categories/${category_id}/`, 'put', {}, {'name': category_name});
    return responseData;
}

export async function addCommunity(community_name, image) {
    let bodyFormData = new FormData();
    bodyFormData.append('name', community_name);
    bodyFormData.append('image', image);

    const responseData = await sendRequest(`/admin/communities/`, 'post', {}, bodyFormData);
    return responseData;
}

export async function editCommunity(community_id, community_name, image) {
    let bodyFormData = new FormData();
    bodyFormData.append('name', community_name);
    bodyFormData.append('image', image);

    const responseData = await sendRequest(`/admin/communities/${community_id}/`, 'put', {}, bodyFormData);
    return responseData;
}

export async function sendRegistrationEmailsForCommunity(community_id) {
    const responseData = await sendRequest(`/admin/communities/${community_id}/send-registration-emails/`, 'get');
    return responseData;
}

//Modifier les informations d'un membre d'une communauté
export async function editmembers(member_id, data) {
    const responseData = await sendRequest(`/admin/members/${member_id}/`, 'put', {}, data, {'Content-Type': 'multipart/form-data','Accept': '*/*'});
    return responseData;
}

//Ajouter un nouveau membre à une communauté
export async function addmembers(data) {
    const responseData = await sendRequest(`/admin/members/`, 'post', {}, data, {'Content-Type': 'multipart/form-data','Accept': '*/*'});
    return responseData;
}

// Request to get a person
export async function getPerson(person_id) {
    const responseData = await sendRequest(`/admin/persons/${person_id}/`, 'get');
    return responseData.item;
}

// Request to disable a member
export async function disableMember(member_id) {
    const responseData = await sendRequest(`/admin/members/${member_id}/`, 'delete');
    return responseData;
}

// Request to disable a person
export async function disablePerson(person_id) {
    const responseData = await sendRequest(`/admin/persons/${person_id}/`, 'delete');
    return responseData;
}

// Request to remove a person
export async function deletePerson(person_id) {
    let bodyFormData = new FormData();
    bodyFormData.append('delete', true);
    const responseData = await sendRequest(`/admin/persons/${person_id}/`, 'delete', {}, bodyFormData);
    return responseData;
}