/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    route: '/app',
    rest_api_url: '/restapi/',
    rest_api_url_image:'/restapi/images/',
    websocket_service: 'wss://alerte70.fr/ws/',
    server: 'https://alerte70.fr/',
    REACT_APP_AUTHORIZE_ENDPOINT: 'https://alerte70.fr/o/authorize/',
    REACT_APP_TOKEN_ENDPOINT: 'https://alerte70.fr/o/token/',
    REACT_APP_CLIENT_ID: '12JSR0RudRZVl02smcoZOgFY6mgpBg3QPnqHlyO3',
    REACT_APP_CLIENT_SECRET: '6DIYLQasdYoVs7taEmlLCBVsvRr23FG3CjppkN6ZR8egenNGLVWVJZfT3Me1EoA4OkKg1smpgQHGyEXpJSJOzViNDVHFRR7RjCK6ls1vhZTZdl51wc8g12p1QFB7kpX4',
    REACT_APP_REDIRECT_URI: window.location.origin,
    REACT_APP_LOCATION: window.location,
    DEPARTEMENT: 'Haute-Saône',
    REGION: 'Franche-Comté'
}