import React, { Component } from 'react';
import Select from 'react-select';
import Async from 'react-select/async';
import swal from 'sweetalert';
import Modal from "../Generics/Modal";
import {withRouter} from 'react-router-dom'
import config from '../../../config';

import './../Dashboard/scss/text_editor.css'
import CancelTimer from './CancelTimer'
import 'react-circular-progressbar/dist/styles.css';

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

/** Text Editor */
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { markdownToDraft, draftToMarkdown } from 'markdown-draft-js';
/** Text Editor  */

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import { getCommunitiesSelectOptions, getCitiesSelectOptionsAsync, getCategoriesSelectOptions, getAlertImage, getNbPeopleAffected, addAlert, editAlert } from '../../webServices';

import Map from '../../Map/Map';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

class AlertModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            selectedcom: [],
            selectedcat: [],
            editorState: EditorState.createEmpty(),
            category_active: [],
            commu_active: [],
            whole_dep_checked: false,
            show: false,
            city: null,
            range: 10,
            markerLocation: [47.6234719, 6.1559043],
            title: "",
            description_md: "",
            files: [],
            processing: false,
            title_has_changed: false,
            desc_has_changed: false,
            image_has_changed: false,
            nb_people_affected: 0,
            circularProgressValue:100
        };

        this.rangeTimeoutID = undefined;
        this.initState = this.initState.bind(this);
        this.handleModifCom = this.handleModifCom.bind(this);
        this.handleModifCat = this.handleModifCat.bind(this);
        this.handlePerimeter = this.handlePerimeter.bind(this);
        this.handleCityChanged = this.handleCityChanged.bind(this);
        this.handleRangeChange = this.handleRangeChange.bind(this);
        this.handleModifFile = this.handleModifFile.bind(this);
        this.handleModifTitle = this.handleModifTitle.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.rien = this.rien.bind(this);
        this.sendForm = this.sendForm.bind(this);
        this.HandleCancel = this.HandleCancel.bind(this);
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    HandleCancel(e) {
        e.preventDefault();
        this.setState({ processing: false });
    }

    rien() {

    }

    async updateNbPeopleAffected() {
        if (this.state.selectedcom.length === 0 || (!this.state.whole_dep_checked && !this.state.city)) {
            this.setState({
                nb_people_affected: 0
            });
            return;
        }

        let communities_pk = [];
        for (let i in this.state.selectedcom) {
            communities_pk.push(this.state.selectedcom[i].value);
        }

        var bodyFormData = new FormData();
        bodyFormData.append('communities', "[" + communities_pk + "]");
        if (this.state.whole_dep_checked) {
            bodyFormData.append('whole_dep', true);
        } else {
            if (!this.state.city) {
                return;
            }

            bodyFormData.append('city', this.state.city.value);
            bodyFormData.append('range', this.state.range);
        }

        let res = await getNbPeopleAffected(bodyFormData);
        this.setState({
            nb_people_affected: res.nb_people_affected
        });
    }

    //init state Data
    async initState() {

        if (!this.props.alert) {
            return;
        }

        let categories = [];

        for (let i in this.props.alert.categories) {
            categories.push({
                value: this.props.alert.categories[i].pk,
                label: this.props.alert.categories[i].name
            });
        }

        let communities = [];
        for (let i in this.props.alert.communities) {
            communities.push({
                value: this.props.alert.communities[i].pk,
                label: this.props.alert.communities[i].name
            });
        }

        let image = null;
        if (this.props.alert.has_image) {
            image = await getAlertImage(this.props.alert.id);
        }

        const rawData = markdownToDraft(this.props.alert.description);
        let editorState;

        if (rawData) {
            const contentState = convertFromRaw(rawData);
            editorState = EditorState.createWithContent(contentState);
        }
        else {
            editorState = EditorState.createEmpty();
        }

        this.setState({
            page: 1,
            range: this.props.alert.range,
            markerLocation: this.props.alert.whole_dep ? [47.6234719, 6.1559043] : [this.props.alert.city.coord.lat, this.props.alert.city.coord.lon],
            city: this.props.alert.city ? { value: this.props.alert.city.pk, label: `${this.props.alert.city.name} (${this.props.alert.city.zip})` } : null,
            whole_dep_checked: this.props.alert.whole_dep,
            title: this.props.alert ? this.props.alert.title : '',
            title_has_changed : this.props.alert.title ? this.props.alert.title : false,
            description_md: this.props.alert.description,
            desc_has_changed : this.props.alert.description ? this.props.alert.description : false,
            files: image,
            selectedcat: categories,
            selectedcom: communities,
            editorState: editorState,
            image_has_changed: false,
        });
    }

    //handle modification Communautés
    handleModifCom(selectedValues) {
        this.setState({ selectedcom: selectedValues });
    }

    //handle Modification Categorie
    handleModifCat(selectedValues) {
        this.setState({ selectedcat: selectedValues });
    }

    //
    nextPage() {
        this.setState({
            page: this.state.page + 1
        });
    }

    //
    onEditorStateChange(editorState) {
        let description_md = editorState && draftToMarkdown(convertToRaw(editorState.getCurrentContent()));
        if (description_md === this.state.description_md) {
            this.setState({ editorState: editorState });
            return;
        }

        this.setState({
            editorState: editorState,
            desc_has_changed: true,
            description_md: description_md
        });
    };

    //
    previousPage() {
        this.setState({
            page: this.state.page - 1
        });
    }

    //handle Perimeter
    handlePerimeter() {
        if (this.state.processing) { return; }
        this.setState({ whole_dep_checked: !this.state.whole_dep_checked });
        if (this.state.whole_dep_checked) {
            this.showHideName = 'display-block';
        }
        else {
            this.showHideName = 'display-none';
        }
    }

    //handle Range
    handleRangeChange(e) {
        const circonference = e.target.value;
        requestAnimationFrame(() => {
            this.setState({ range: circonference });
        });
    }

    //handle Dropdown Change
    handleCityChanged(city) {
        this.setState({
            city: city,
            markerLocation: [city.data.coord.lat, city.data.coord.lon]
        });
    }

    handleModifFile(fileItems) {
        // Set current file objects to this.state
        this.setState({
            files: fileItems.map((fileItem) => fileItem.file),
            // eslint-disable-next-line eqeqeq
            image_has_changed: this.state.image_has_changed || !this.state.files || this.state.files.length == 0
        });
    }

    handleModifTitle(e) {
        this.setState({
            title: e.target.value,
            title_has_changed: true
        });
    }

    //Add Alert
    sendForm() {
        var bodyFormData = new FormData();

        let communities = [];
        if (this.state.selectedcom && this.state.selectedcom.length > 0) {
            this.state.selectedcom.forEach(community => { communities.push(community.value); });
        }

        let categories = [];
        if (this.state.selectedcat && this.state.selectedcat.length > 0) {
            this.state.selectedcat.forEach(category => { categories.push(category.value); });
        }

        bodyFormData.append('communities', "[" + communities + "]");
        bodyFormData.append('categories', "[" + categories + "]");
        bodyFormData.append('whole_dep', this.state.whole_dep_checked);

        if (!this.state.whole_dep_checked) {
            bodyFormData.append('city', this.state.city.value);
            bodyFormData.append('range', this.state.range);
        }

        if (this.props.alert) {
            if (this.state.title_has_changed) {
                bodyFormData.append('title', this.state.title);
            }

            if (this.state.desc_has_changed) {
                bodyFormData.append('description', this.state.description_md);
            }

            if (this.state.files && this.state.image_has_changed && this.state.files[0]) {
                bodyFormData.append('image', this.state.files[0] || null);
            }

            // eslint-disable-next-line eqeqeq
            else if (!this.state.files || this.state.files.length == 0 || !this.state.files[0]) {
                bodyFormData.append('no_image', 1);
            }
        }

        else {
            bodyFormData.append('title', this.state.title);
            bodyFormData.append('description', this.state.description_md);
            if (this.state.files) {
                bodyFormData.append('image', this.state.files[0] || null);
            }
        }

        if (this.props.alert) {
            editAlert(this.props.alert.id, bodyFormData)
            .then(data => {
                if (!data || !data.success) {
                    swal({
                        icon: "error",
                        title: "Une erreur est survenue",
                        text: "Impossible de modifier l'alerte."
                    });
                    this.setState({ processing: false });
                    return;
                }
                swal({
                    icon: "success",
                    title: "Alerte Modifiée",
                    timer: 1500
                });
                this.hideModal();

                //Reload After Modify 
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            })
            .catch(err => {
                swal({
                    icon: "error",
                    title: "Une erreur est survenue",
                    text: "Impossible de modifier l'alerte."
                });
                this.setState({ processing: false });
                //TODO
            });
        }
        else {
            addAlert(bodyFormData)
            .then(data => {
                if (!data || !data.success) {
                    swal({
                        icon: "error",
                        title: "Une erreur est survenue",
                        text: "Impossible de créer l'alerte."
                    });
                    this.setState({ processing: false });
                    return;
                }
                swal({
                    icon: "success",
                    title: "Alerte Créée",
                    timer: 1500
                });
                this.hideModal();
                setTimeout(() => {
                    if (window.location.pathname === config.route || window.location.pathname === `${config.route}/`) {
                        window.location.reload();
                    } else {
                        this.props.history.push("/");
                    }
                }, 1500);
            })
            .catch(err => {
                swal({
                    icon: "error",
                    title: "Une erreur est survenue",
                    text: "Impossible de créer l'alerte."
                });
                this.setState({ processing: false });
                //TODO
            });
        }
    }

    handleFormSubmit = (e) => {
        e.preventDefault();
        this.setState({ processing: true });
    };

    handleClose() {
        this.setState({ processing: false, title:"", title_has_changed:false , description_md: null, desc_has_changed:false});
        if (typeof this.props.handleClose === "function") {
            this.props.handleClose();
        }
    }

    //hide Modal
    hideModal() {
        this.setState({
            show: false,
            processing: false,
            title : "",
            title_has_changed: false,
            description_md :"",
            desc_has_changed : false
        });
        this.showHideName = "display-none";
    }

    //
    async componentDidMount() {
        const communities = await getCommunitiesSelectOptions();
        const categories = await getCategoriesSelectOptions();

        this.setState({
            commu_active: communities,
            category_active: categories,
        });

        this.initState();
    }

    //
    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.alert && this.props.alert && prevProps.alert.id !== this.props.alert.id) {
            this.initState();
        }

        if (prevState.selectedcom !== this.state.selectedcom || prevState.whole_dep_checked !== this.state.whole_dep_checked || prevState.city !== this.state.city || prevState.range !== this.state.range) {
            if (typeof this.rangeTimeoutID === 'number') {
                clearTimeout(this.rangeTimeoutID);
            }

            this.rangeTimeoutID = setTimeout(() => { this.updateNbPeopleAffected(); }, 250);
        }
    }

    handleInit() {

    }

    render() {
        const { editorState } = this.state;

        let current_city = null;
        if (!this.state.whole_dep_checked) {
            const city_name = this.state.city ? this.state.city.label : "";
            current_city = { name: city_name, coord: { lat: this.state.markerLocation[0], lon: this.state.markerLocation[1] } };
        }

        let nb_people_affected_wgt;
        if (this.state.nb_people_affected === 0) {
            nb_people_affected_wgt = <span className='nb_people_affected nobody'>Personne ne recevra la notification</span>;
        } else if (this.state.nb_people_affected === 1) {
            nb_people_affected_wgt = <span className='nb_people_affected'><span className="bold">1</span> membre recevra la notification</span>;
        } else {
            nb_people_affected_wgt = <span className='nb_people_affected'><span className="bold">{this.state.nb_people_affected}</span> membres recevront la notification</span>;
        }

        return (
            <>
                <Modal show={this.props.show} handleClose={this.handleClose} title={this.props.title}>
                <div className="card-inner">
                    <form onSubmit={this.handleFormSubmit} id="add_alert">

                        <div style={this.state.page === 1 ? null : { display: 'none' }}>

                            <div className="form-group">
                                <div className="form-control-wrap">
                                    <input type="text" name="title" onChange={this.handleModifTitle} value={this.state.title} placeholder="Titre de l'alerte" className={`form-control form-control-xl form-control-outlined ${this.state.title_has_changed && this.state.title.trim().length === 0 ? 'is-invalid' : ''}`} id="outlined-normal" required />
                                    <small className={'invalid-feedback'}> Titre de l'alerte obligatoire </small>
                                </div>
                            </div>

                            <div className="form-group">
                                <div id="editor-wrapper" className={this.state.desc_has_changed && this.state.description_md.trim().length === 0 ? 'invalid is-invalid' : ''}>
                                    <Editor
                                        wrapperClassName="description-wrapper"
                                        editorClassName="description-editor"
                                        initialEditorState={editorState}
                                        editorState={editorState}
                                        onEditorStateChange={this.onEditorStateChange}
                                        stripPastedStyles={true}
                                    />
                                </div>
                                <small className={'invalid-feedback'}> Texte descriptif obligatoire </small>
                            </div>

                            <div className="form-group">
                                <FilePond
                                    ref={(ref) => (this.pond = ref)}
                                    files={this.state.files}
                                    allowMultiple={false}
                                    maxFiles={1}
                                    oninit={() => this.handleInit()}
                                    onupdatefiles={this.handleModifFile}
                                    name="image"
                                    labelIdle='Faites glisser vos fichiers ou <span class= "filepond--label-action"> Parcourir <span>'
                                />
                            </div>

                            <div className="form-group">
                                <button type="button" className="btn btn-lg btn-primary" onClick={this.nextPage} disabled={this.state.title.trim().length === 0 || this.state.description_md.trim().length === 0}> Suivant </button>
                            </div>
                        </div>

                        <div style={this.state.page === 2 ? null : { display: 'none' }}>

                            <div className="form-group">
                                <div className="form-control-wrap-outlined">
                                    <label className="form-label labells"> Communautés </label>

                                    <Select
                                        name="communities"
                                        value={this.state.selectedcom}
                                        options={this.state.commu_active}
                                        isMulti
                                        onChange={this.handleModifCom}
                                        isDisabled={this.state.processing}
                                    />

                                </div>
                            </div>

                            <div className="form-group">
                                <label className="form-label labells"> Catégories (Statistiques)</label>
                                <div className="form-control-wrap">

                                    <Select
                                        name="categories"
                                        value={this.state.selectedcat}
                                        options={this.state.category_active}
                                        isMulti
                                        onChange={this.handleModifCat}
                                        isDisabled={this.state.processing}
                                    />

                                </div>
                            </div>

                            <div className={"form-group " + (this.state.whole_dep_checked ? 'display-none' : 'display-block')}>
                                <label className="form-label labells"> Commune </label>
                                <div className="form-control-wrap" >

                                    <Async
                                        name="city"
                                        loadOptions={getCitiesSelectOptionsAsync}
                                        onChange={this.handleCityChanged}
                                        noOptionsMessage={() => { return "Tapez le nom de la ville ou son code postal"; }}
                                        value={this.state.city}
                                        defaultOptions={this.state.city ? [this.state.city] : null}
                                        isDisabled={this.state.processing}
                                    />

                                </div>
                            </div>

                            <div className="form-group">

                                <Map whole_dep={this.state.whole_dep_checked} city={current_city} range={this.state.range} mapId={this.props.alert ? "update_alert_map" : "create_alert_map"} heightREM="22.854" ShowMapInfo={false} visible={this.state.page === 2} />

                            </div>

                            <div className="">

                                <label className="form-label labells"> Rayon d'action </label>

                                <div className="row g-3">

                                    <div className={"col-auto with_perso_range " + (this.state.whole_dep_checked ? 'display-none' : 'display-block')}>
                                        <input type="number" name="range" value={this.state.range} onChange={this.handleRangeChange} min="0" max="50" className="form-control" id="outlined-normal" disabled={this.state.processing}/>
                                    </div>

                                    <div className={"col-auto with_perso " + (this.state.whole_dep_checked ? 'display-none' : 'display-block')}>
                                        <input type="range" name="Default_Range" value={this.state.range} onChange={this.handleRangeChange} className="form-control" min="0" max="50" step="2" disabled={this.state.processing}/>
                                    </div>

                                    <div className="col-auto with_perso">
                                        <div className="custom-control custom-switch" style={{ 'top': '7px' }}>
                                            <input type="checkbox" checked={this.state.whole_dep_checked} value={this.state.whole_dep_checked} onChange={this.rien} className="custom-control-input" name="whole_dep" id="whole_dep" disabled={this.state.processing}/>
                                            <label className="custom-control-label" onClick={this.handlePerimeter} htmlFor="whole_dep"> Tout le département </label>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <br />

                            <div className="form-group ">
                                <button type="button" className="btn btn-lg btn-info" onClick={this.previousPage} disabled={this.state.processing}> Précedent </button> &nbsp;
                                <button type="submit" className={`btn btn-lg btn-success ${this.state.processing ? "hidden" : ""}`} id="Submit_btn" disabled={!this.state.nb_people_affected}> {this.props.alert ? "Modifier l'alerte" : "Créer l'alerte"} </button>
                                <button className={`btn btn-lg btn-danger ${this.state.processing ? "" : "hidden"}`} onClick={this.HandleCancel} id="Cancel_btn">
                                    Annuler
                                    <CancelTimer running={this.state.processing} onTimeout={this.sendForm} delai={10000}/>
                                </button>

                                

                                {nb_people_affected_wgt}

                            </div>

                        </div>

                    </form>
                </div>
                </Modal>
            </>
        );
    }
}

export default withRouter(AlertModal);