import React, { Component } from 'react';
import Modal from "../Generics/Modal.jsx";
import Async from 'react-select/async';
import swal from 'sweetalert';
import { getPerson, disableMember, disablePerson, deletePerson, getUserPermissions, getUserLimitations } from '../../webServices';

class MemberEditModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            member: null,
        };


        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.initState = this.initState.bind(this);
        this.unsubscribeCommunityMember = this.unsubscribeCommunityMember.bind(this);
        this.disablePerson = this.disablePerson.bind(this);
        this.fullRemovePerson = this.fullRemovePerson.bind(this);
    }

    async initState(member) {
        let person = await getPerson(member.person_pk);
        const limitations = await getUserLimitations();
        const permissions = await getUserPermissions();

        let communities_limitations = false;
        if (limitations) {
            communities_limitations = [];
            limitations.communities.forEach(community => communities_limitations.push(community.pk));
        }

        console.log(communities_limitations);

        this.setState({
            show: true,
            member: member,
            person: person,
            full_name: `${member.firstname} ${member.lastname}`,
            nb_communities: person.communities.length,
            processing: false,
            can_delete_communitymember: permissions != undefined && permissions.includes("restapi.delete_communitymember"),
            can_delete_person: permissions != undefined && permissions.includes("restapi.delete_person"),
            communities_limitations: communities_limitations
        });
    }

    show(member = null, callback = null) {
        this.initState(member);
        this.callback = callback;
    }

    hide() {
        this.setState({ show: false, member: null, person: null, full_name: '', nb_communities: 0, processing: false });
    }

    displayFailure(text) {
        swal({
            icon: "error",
            title: "Une erreur est survenue",
            text: text
        });
        this.setState({ processing: false });
    }

    displaySuccess(text) {
        swal({
            icon: "success",
            title: text,
            timer: 1500
        });
    }

    unsubscribeCommunityMember(community_member) {
        this.setState({ processing: true });
        let community = community_member.community;

        let content = document.createElement("p");
        content.innerHTML = `Êtes vous sûr de vouloir désinscrire <strong>${this.state.full_name}</strong> de la communauté <strong>${community.name}</strong> ?`;

        swal({
            title: `Désinscription`,
            content: content,
            icon: "warning",
            dangerMode: false,
            buttons: {
                cancel: "Annuler",
                confirm: `Désinscrire ${this.state.full_name}`,
            },
        })
        .then(willDelete => {
            if (!willDelete) {
                this.setState({ processing: false });
                return;
            }

            disableMember(community_member.pk)
            .then(data => {
                if (!data || !data.success) {
                    this.displayFailure("Impossible de désinscrire le membre.");
                    return;
                }
                this.displaySuccess("Membre désinscrit avec succès");

                if (community_member.pk === this.state.member.pk && typeof this.callback === "function") {
                    this.callback();
                }

                let member_row = document.querySelector(`tr[data-member-id="${community_member.pk}"]`);
                if (member_row) {
                    member_row.style.display = "none";
                }

                this.setState({ processing: false, nb_communities: this.state.nb_communities-1 });
            })
            .catch(err => {
                this.displayFailure("Impossible de désinscrire le membre.");
            });
        });
    }

    disablePerson() {
        this.setState({ processing: true });

        let content = document.createElement("p");
        content.innerHTML = `Êtes vous sûr de vouloir désactiver <strong>${this.state.full_name}</strong> ?`;

        swal({
            title: `Désactivation`,
            content: content,
            icon: "warning",
            dangerMode: true,
            buttons: {
                cancel: "Annuler",
                confirm: `Désactiver ${this.state.full_name}`,
            },
        })
        .then(willDelete => {
            if (!willDelete) {
                this.setState({ processing: false });
                return;
            }
            
            disablePerson(this.state.person.pk)
            .then(data => {
                if (!data || !data.success) {
                    this.displayFailure("Impossible de désactiver la personne.");
                    return;
                }
                this.displaySuccess("Personne désactivée avec succès");
                this.hide();

                if (typeof this.callback === "function") {
                    this.callback();
                } else {
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                }
            })
            .catch(err => {
                this.displayFailure("Impossible de désactiver la personne.");
            });
        });
    }

    fullRemovePerson() {
        this.setState({ processing: true });

        let content = document.createElement("p");
        content.innerHTML = `Êtes vous sûr de vouloir complètement supprimer <strong>${this.state.full_name}</strong> ainsi que <strong>toutes ses données personnelles</strong> ?`;

        swal({
            title: `Suppression complète`,
            content: content,
            icon: "warning",
            dangerMode: true,
            buttons: {
                cancel: "Annuler",
                confirm: `Supprimer ${this.state.full_name}`,
            },

          })
        .then(willDelete => {
            if (!willDelete) {
                this.setState({ processing: false });
                return;
            }

            deletePerson(this.state.person.pk)
            .then(data => {
                if (!data || !data.success) {
                    this.displayFailure("Impossible de supprimer la personne.");
                    return;
                }
                this.displaySuccess("Personne supprimée avec succès");
                this.hide();

                if (typeof this.callback === "function") {
                    this.callback();
                } else {
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                }
            })
            .catch(err => {
                this.displayFailure("Impossible de supprimer la personne.");
            });
        });
    }

    render() {
        if (!this.state.person) {
            return <></>;
        }

        const { can_delete_communitymember, can_delete_person } = this.state;

        return (
            <Modal size="xl" show={this.state.show} handleClose={this.hide} title={`Désinscrire / Désactiver ${this.state.full_name}`} >
                <div className="card-inner">
                    { can_delete_communitymember ?
                        <div className="popup-section">
                            <h5>Désinscription</h5>
                            <p>Utilisez cette action si {this.state.full_name} ne fait plus partie de la communauté choisi. Par exemple, si le mandat d'un élu n'est pas renouvelé ou si un commercant cesse son activité.</p>

                            <p>{this.state.full_name} ne recevra plus les notifications à destination de cette communauté, mais pourra continuer à utiliser l'application ALERTE70 dans le cas où il serait membre d'une autre communauté ou s'il est inclue de nouveau dans cette communauté:</p>

                            <p>{this.state.full_name} est membre de {this.state.nb_communities} communauté{this.state.nb_communities > 1 ? 's': ''} :</p>
                        
                            <table className='list_user_commu'>
                                <thead>
                                    <tr>
                                        <th>Communauté</th>
                                        <th>Ville</th>
                                        <th>Nom</th>
                                        <th>Email</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.person.members.map((data, key) => (
                                        <tr key={key} data-member-id={data.pk}>
                                            <th> { data.community.name } </th>
                                            <td> { data.city.name } ({ data.city.zip }) </td>
                                            <td> { data.firstname } { data.lastname }</td>
                                            <td> { data.email } </td>
                                            <td>
                                                {
                                                    this.state.communities_limitations === false || this.state.communities_limitations.includes(data.community.pk)
                                                    ? <button className='link-btn' onClick={() => { this.unsubscribeCommunityMember(data); }} disabled={this.state.processing}>Désinscrire</button>
                                                    : null
                                                }
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                        : null
                    }
                    { can_delete_communitymember && can_delete_person ? <div className='popup-section-sep'></div> : null }
                    { can_delete_person ?
                        <>
                            <div className="popup-section">
                                <h5>Désactivation</h5>
                                <div>
                                    <p>Utilisez cette action si vous souhaitez exclure {this.state.full_name} du dispositif ALERTE70 ou si {this.state.full_name} ne plus souhaite plus en faire partie.</p>

                                    <p className='msg-warning'><strong>Attention:</strong> si vous utilisez cette action, <strong>{this.state.full_name} ne pourra plus utiliser l'application ALERTE70</strong>, même si il/elle est de nouveau inclu au dispositif par la création d'un nouveau membre de communauté ou via l'import.</p>
                                    <p>Les données personnelles de {this.state.full_name} seront conservées, il sera donc toujours possible de le réactiver.</p>
                                </div>
                                <div className='actions-block'>
                                    <button id="disable-person" className="btn btn-lg btn-danger bg-gradient" onClick={this.disablePerson} disabled={this.state.processing}>Désactiver</button>
                                    {/*<label><input type="checkbox" id="disabled-by-user-request" name="disabled-by-user-request"/> à la demande de l'utilisateur</label>*/}
                                </div>
                            </div>
                            <div className='popup-section-sep'></div>
                            <div className="popup-section">
                                <h5>Suppression complète des données personnelles</h5>
                                <div>
                                    <p>Utilisez cette action si {this.state.full_name} souhaite la suppression compète de ses données personnelles dans le cadre du <strong>Règlement Général sur la Protection des Données (RGPD)</strong>.</p>
                                    <p>Cette personne ne pourra alors plus utiliser l'application ALERTE70 et ne recevra plus de notification.</p>

                                    <p className='msg-warning'><strong>Attention:</strong> Dans la mesure ou l'ensemble des données personnelles de {this.state.full_name} seront supprimées, <strong>cette action est irréversible.</strong></p>

                                    <p className='msg-warning'><strong>Attention:</strong> Pensez bien à <strong>retirer {this.state.full_name} de TOUT les fichiers</strong> car il ne sera pas possible de l'exclure automatiquement lors des prochains imports.</p>
                                </div>
                                <div className='actions-block'>
                                    <button id="full-remove-person" className="btn btn-lg btn-danger bg-gradient" onClick={this.fullRemovePerson} disabled={this.state.processing}> Supprimer toutes les données personnelles</button>
                                </div>
                            </div>
                        </>
                        : null }
                </div>
            </Modal>
        );
    }
}

export default MemberEditModal;