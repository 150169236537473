import React, { Component } from "react";
import config from '../../../../config';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { getChartsResponseProgress, getAccesToken } from '../../../webServices'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend);

class ChartWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      range: 10,
      timeValue: this.getTimesLabel(10),
      data: [],
    };

    this.nb_slots = 6;
    this.ws = null;
    this.timeoutID = undefined;
    this.wsConnect = this.wsConnect.bind(this);
    this.wsClose = this.wsClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateData = this.updateData.bind(this);
    this.initTimeout = this.initTimeout.bind(this);
    this.updateDataOnTimeout = this.updateDataOnTimeout.bind(this);
  }

  async updateData() {
    const data = await getChartsResponseProgress(this.props.alert.id, this.state.range);
    this.setState({
      data: this.getDataset(data)
    });
  }

  initTimeout() {
    if (typeof this.timeoutID === 'number') {
      clearTimeout(this.timeoutID);
    }

    const current_ts = parseInt((new Date()).getTime() / 1000);
    const range_sec = this.state.range * 60;
    const next_update_ts = parseInt(current_ts / range_sec) * (range_sec) + range_sec;
    const delta_s = next_update_ts - current_ts;

    this.timeoutID = setTimeout(this.updateDataOnTimeout, delta_s * 1000);
  }

  async updateDataOnTimeout() {
    await this.updateData();
    this.initTimeout();
  }

  getTimesLabel(range) {
    switch (range) {
      case 10080://Semaines
        return ["il y a 5 sem", "il y a 4 sem", "il y a 3 sem", "il y a 2 sem", "il y a 1 sem", "Cette semaine"];
      case 1440://Jours
        return ["il y a 5 jrs", "il y a 4 jrs", "il y a 3 jrs", "il y a 2 jrs", "il y a 1 jr", "Aujourd'hui"];
      case 60://Heures
        return ["il y a 5 hrs", "il y a 4 hrs", "il y a 3 hrs", "il y a 2 hrs", "il y a 1 hr", "Maintenant"];
      case 10://Minutes
      default:
        return ["il y a 50 min", "il y a 40 min", "il y a 30 min", "il y a 20 min", "il y a 10 min", "Maintenant"];
    }
  }

  async handleChange(e) {
    const range = parseInt(e.target.value);

    if (this.state.range === range) {
      return;
    }

    let timeValue = this.getTimesLabel(range);

    this.setState({
      timeValue: timeValue,
      range: range
    });

    document.getElementsByClassName('evol_button').forEach(element => {
      element.classList.remove("active");
    });

    document.getElementById(`evol_${range}`).classList.add("active");

  };

  wsConnect() {
    this.wsClose();
    this.ws = new WebSocket(`${config.websocket_service}alert/${this.props.alert.id}/responseprogress-${this.state.range}-${this.nb_slots}?subscribe-broadcast&access_token=${getAccesToken()}`);
    
    this.ws.onopen = function () {
    }

    this.ws.onmessage = (e) => {
      var res = JSON.parse(e.data);
      if (res.widget === "responseprogress") {
        this.setState({
          data: this.getDataset(res.data)
        });
      }
    }

    let context = this;
    this.ws.onerror = function (e) {
      console.error("ws error: ", e);
      context.wsClose();
    }

    this.ws.onclose = function (evt) {
    }
  }

  wsClose() {
    if (this.ws && this.ws.readyState === 1) {
      this.ws.close();
    }
  }

  getDataset(api_data) {

    var data = [
      {
        label: 'Merci',
        data: [],
        borderColor: '#9E9E9E',
        backgroundColor: '#9E9E9E',
        barThickness: 35,
        borderRadius: 5,
      },
      {
        label: 'Pas dans le département',
        data: [],
        borderColor: '#FBC02D',
        backgroundColor: '#FBC02D',
        barThickness: 35,
        borderRadius: 5,
      },
      {
        label: 'Pas dans la zone',
        data: [],
        borderColor: '#1B5E20',
        backgroundColor: '#1B5E20',
        barThickness: 35,
        borderRadius: 5,
      },
      {
        label: 'Je reste vigilant(e)',
        data: [],
        borderColor: '#E65100',
        backgroundColor: '#E65100',
        barThickness: 35,
        borderRadius: 5,
      },
      {
        label: 'J\'ai des infos',
        data: [],
        borderColor: '#D32F2F',
        backgroundColor: '#D32F2F',
        barThickness: 35,
        borderRadius: 5,
      },
    ];

    for (var i = 0; i < api_data.nb_slots; ++i) {

      var responses = api_data.slots[i].responses;

      for (var res = 0; res < 5; ++res) {
        data[res].data.push(responses.hasOwnProperty(res + 1) ? responses[res + 1] : 0);
      }

    }
    
    return data;
  }

  //
  async componentDidMount() {
    this.wsConnect();
    await this.updateData();
    this.initTimeout();
  }

  //
  async componentDidUpdate(prevProps, prevState, snapshot) {
    // eslint-disable-next-line eqeqeq
    if (prevProps.alert.id !== this.props.alert.id || prevState.range != this.state.range) {
      this.wsConnect();
      await this.updateData();
      this.initTimeout();
    }
  }

  componentWillUnmount() {
    this.wsClose();
  }

  render() {
    const options = {
      responsive: true,
      tension: 0.4,
      plugins: {
        legend: {
          position: 'top',
          labels: {
            usePointStyle: true,
            pointStyle: 'rect',
            font: {
              size: 14
            }
          }
        },
        title: {
          display: true,
        },
      },
      //responsive:true,
      scales: {
        y: {
          beginAtZero: true,
          stacked: true,
          suggestedMin: 0,
          suggestedMax: 1,
          ticks: {
            stepSize: 1,
          }
        },
        x: {
          barPercentage: 0.1,
          beginAtZero: true,
          stacked: true,
          grid: {
            display: false
          }
        },
      },
    };

    const Time = [
      {
        value: 'week',
        name: 'Semaines',
        ranges: 10080
      },
      {
        value: 'days',
        name: 'Jours',
        ranges: 1440
      },
      {
        value: 'hours',
        name: 'Heures',
        ranges: 60
      },
      {
        value: 'minutes',
        name: 'Minutes',
        ranges: 10,
      }
    ];

    return (
      <div className="card card-bordered truc_border_opening_evol eval">
        <div className="card-inner dd ">
          <div className="card-title-group align-start pb-3 g-2">
            <div className="card-title truc_evo">
              <h6 className="title title_evol"> Évolution de l'alerte </h6>
            </div>
            <div className="btn-toolbar mb-3 tools_truc">
              <div className="btn-group btn-group-sm">

                {
                  Time.map((time, key) =>
                    <button
                      onClick={this.handleChange}
                      key={key}
                      id={`evol_${time.ranges}`}
                      title={time.value}
                      value={time.ranges}
                      className={`btn btn-primary evol_button ${this.state.range === time.ranges ? 'active' : ''}`}> {time.name} </button>
                  )
                }

              </div>
            </div>
          </div>
          <div className="analytic-au">
            <Bar
              options={options}
              data={{
                labels: this.state.timeValue,
                datasets: this.state.data
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ChartWidget;