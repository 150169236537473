import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getUserPermissions } from "./webServices";

class Navlink extends Component {
    render() {
        let { path, label } = this.props;
        const name = path ? path : "dashboard";
        path = path ? `/${path}` : "/";
        const active = window.location.pathname.endsWith(path);
        const classname = active ? "active" : "";

        return (
            <>
                <li id={name} className={`item ${classname}`}>
                    <Link className="link" to={path} disabled={active}> {label} </Link>
                    {active ? <hr className="nav-hr" /> : null}
                </li>
            </>
        );
    }
}

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    async componentDidMount() {
        const permissions = await getUserPermissions();
    
        this.setState({
            can_view_alert: permissions != undefined && permissions.includes("restapi.view_alert"),
            can_view_community: permissions != undefined && permissions.includes("restapi.view_community"),
            can_view_category: permissions != undefined && permissions.includes("restapi.view_category"),
            can_view_import: permissions != undefined && permissions.includes("restapi.view_importedfile"),
        });
    }

    render() {
        const { can_view_alert, can_view_community, can_view_category, can_view_import } = this.state;

        return (
            <div className="nk-header-news d-none d-xl-block">
                <div className="nk-news-list">
                    <nav>
                        <ul className="breadcrumb">
                            {can_view_alert ? <Navlink label="Dashboard" /> : null }
                            {can_view_alert ? <Navlink path="historique" label="Historique des alertes" /> : null }
                            {can_view_community ? <Navlink path="communautes" label="Communautés" /> : null }
                            {can_view_category ? <Navlink path="categories" label="Catégories" /> : null }
                            {can_view_import ? <Navlink path="import" label="Import de fichiers" /> : null }
                        </ul>
                    </nav>
                </div>
            </div>
        );
    }
}

export default Navbar;