import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../img/logo-alerte-70-header.png';
import Mainheader from '../../Header';
import ListImportedFiles from './ListImportedFiles'
import ImportFileModal from './ImportFileModal'
import ColumnsMapping from './ColumnsMapping'
import ImportValidation from './ImportValidation'
import swal from 'sweetalert';
import { ImList, ImDownload3 } from 'react-icons/im';
import { getUserPermissions } from '../../webServices';

class Import extends Component {
    constructor(props) {
        super(props);

        this.modalRef = React.createRef();
        this.getListImportedFilesPage = this.getListImportedFilesPage.bind(this);
        this.getImportValidationPage = this.getImportValidationPage.bind(this);
        this.onFileUploaded = this.onFileUploaded.bind(this);
        this.onFileImported = this.onFileImported.bind(this);
        this.continueImport = this.continueImport.bind(this);
        this.goToFileList = this.goToFileList.bind(this);
        this.onColumnsMapped = this.onColumnsMapped.bind(this);

        this.state = {
            current_page: this.getListImportedFilesPage(),
            showImportModal: false
        };   
    }

    async componentDidMount(id) {
        const permissions = await getUserPermissions();
    
        this.setState({
          can_import: permissions != undefined && permissions.includes("restapi.add_importedfile")
        });
    }

    getListImportedFilesPage() {
        return <ListImportedFiles continueImport={this.continueImport}/>
    }

    getColumnsMappingPage(file_pk, file_data=null) {
        return <ColumnsMapping file_data={file_data} pk={file_pk} onColumnsMapped={this.onColumnsMapped} />
    }

    getImportValidationPage(file_pk) {
        return <ImportValidation pk={file_pk} onFileImported={this.onFileImported} />
    }

    onFileUploaded(file_data) {
        this.setState({current_page: this.getColumnsMappingPage(file_data.pk, file_data)});
        this.forceUpdate();
    }

    onFileImported() {
        this.goToFileList();
        swal({
            icon: "success",
            title: "Fichier Importé",
            timer: 3000
        });
    }

    onColumnsMapped(file_pk) {
        this.setState({current_page: this.getImportValidationPage(file_pk)});
    }

    goToFileList() {
        this.setState({
            current_page: this.getListImportedFilesPage(),
            showImportModal: false
        });
    }

    continueImport(file_pk, status) {
        switch(status) {
            case 0:
                this.setState({current_page: this.getColumnsMappingPage(file_pk)});
                break;

            case 1:
                this.setState({current_page: this.getImportValidationPage(file_pk)});
                break;
                
            /** Modification */    
            default :
        }
    }

    render() {
        const { can_import } = this.state;

        return (
            <div>
                <div className="nk-app-root">
                    <div className="nk-main">
                        <div className="nk-sidebar nk-sidebar-fixed is-light " data-content="sidebarMenu">
                            <div className="nk-sidebar-element nk-sidebar-head">

                                <div className="nk-menu-trigger">
                                    <Link to={"/"} className="nk-nav-toggle nk-quick-nav-icon d-xl-none" data-target="sidebarMenu"><em className="icon ni ni-arrow-left" /></Link>
                                    <Link to={"/"} className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex" data-target="sidebarMenu"><em className="icon ni ni-menu" /></Link>
                                </div>

                                <div className="nk-sidebar-brand">
                                    <Link to={'/'} className="logo-link nk-sidebar-logo">
                                        <img className="logo-light logo-img" src={logo} srcSet={logo} width={150} alt="logo" />
                                        <img className="logo-dark logo-img" src={logo} srcSet={logo} width={150} alt="logo-dark" />
                                    </Link>
                                </div>

                            </div>
                            {/* <!-- .nk-sidebar-element --> */}
                            <div className="nk-sidebar-element nk-sidebar-body">
                                <div className="nk-sidebar-content">
                                    <div className="nk-sidebar-menu" data-simplebar>
                                        <div className="nk-menu">
                                            <div className='btn-menu'>
                                                <div className="btn btn-primary import_file" type="button" onClick={this.goToFileList}> <ImList/> &nbsp; Liste des imports</div>
                                                { can_import ? <div className="btn btn-primary import_file" type="button" onClick={() => { this.modalRef.current.show() } }> <ImDownload3/> &nbsp; Importer un fichier</div> : null }
                                            </div>

                                        </div>
                                        {/* <!-- .nk-menu --> */}
                                    </div>
                                    {/* <!-- .nk-sidebar-menu --> */}
                                </div>
                                {/* <!-- .nk-sidebar-content --> */}
                            </div>
                            {/* <!-- .nk-sidebar-element --> */}
                        </div>

                        <div className="nk-wrap">

                            <Mainheader />

                            <div className="nk-content h-100">
                                { this.state.current_page }
                            </div>

                        </div>
                        
                    </div>
                </div>  

                <ImportFileModal ref={this.modalRef} onFileUploaded={this.onFileUploaded}/>
            </div>
        );
    }
}

export default Import;