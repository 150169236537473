import React, { Component } from "react";
import 'react-custom-scroll/dist/customScroll.css';
import { Link } from "react-router-dom";
import { getCommunityMembers, getUserPermissions } from '../../webServices';
import { GenericDataList, Field } from '../Generics/GenericDataList';
import { MdOutlinePhonelinkErase, MdOutlineSmartphone } from 'react-icons/md';
import { ImPlus } from 'react-icons/im';

class CommunityMemberRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalModify: false,
      member: this.props.member,
    };

    this.onMemberModified = this.onMemberModified.bind(this);
    this.onMemberDisabled = this.onMemberDisabled.bind(this);
  }

  async componentDidMount(id) {
    const permissions = await getUserPermissions();

    this.setState({
      can_edit_communitymember: permissions != undefined && permissions.includes("restapi.change_communitymember"),
      can_delete_communitymember: permissions != undefined && permissions.includes("restapi.delete_communitymember"),
      can_delete_person: permissions != undefined && permissions.includes("restapi.delete_person"),
      can_view_person: permissions != undefined && permissions.includes("restapi.view_person"),
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.member.pk !== prevProps.member.pk) {
      this.setState({ member: this.props.member });
    }
  }

  onMemberModified(new_member) {
    this.setState({
      member: new_member
    });

    if (typeof this.props.onDataChanged === "function") {
      this.props.onDataChanged();
    }
  }

  onMemberDisabled() {
    if (typeof this.props.onMemberRemoved === "function") {
      this.props.onMemberRemoved();
    }
  }

  render() {
    const { can_edit_communitymember, can_view_person, can_delete_communitymember, can_delete_person } = this.state;
    const { /**pk, */ firstname, lastname, email, /**community,*/ city, is_active } = this.state.member;

    let active_icone = is_active ?
      <MdOutlineSmartphone title="Ce membre a installé l'application mobile ALERTE70" style={{ color: "#4bbd32", margin: "0px 5px -5px 0px" }} />
     :<MdOutlinePhonelinkErase title="Ce membre n'a pas ou plus l'application mobile ALERTE70" style={{ color: "#e85347", margin: "0px 5px -5px 0px" }} /> ;

    return (
      <>
        <tr>
          <td style={{ fontSize: "1.2rem" }}><div>{active_icone}</div></td>
          <td> {lastname} </td>
          <td> {firstname} </td>
          <td> {email} </td>
          <td> {city.name} ({city.zip}) </td>
          <td className="text-center" style={{ fontSize: "1.2rem" }}>
            <div>
              { can_edit_communitymember ? <Link className="btn btn-trigger btn-icon" to="#" onClick={() => { this.props.editMember(this.state.member, this.onMemberModified); }} title="Modifier"> <em className="icon ni ni-edit-alt edito"></em></Link> : null }
              { can_view_person && (can_delete_communitymember || can_delete_person) ? <Link className="btn btn-trigger btn-icon" to="#" onClick={() => { this.props.disableMember(this.state.member, this.onMemberDisabled); }} title="Désinscrire / Désactiver"> <em className="icon ni ni-user-cross edito"></em></Link> : null }
            </div>
          </td>
        </tr>
      </>
    )

  }
}


class ListCommunityMembers extends GenericDataList {
  constructor(props) {
    super(props, 50);
    this.columns = [new Field("", "",""), new Field("lastname", "nom", true, true), new Field("firstname", "prénom", true, true), new Field("email", "email", true, true), new Field("", "Ville", true, false), new Field("", "Action","")];
    this.loadData = this.loadCommunityMembers.bind(this);
    this.onMemberAdded = this.onMemberAdded.bind(this);
    this.onMemberRemoved = this.onMemberAdded.bind(this);
    this.onDataChanged = this.onDataChanged.bind(this);
  }

  async componentDidMount(id) {
    const permissions = await getUserPermissions();

    this.setState({
      can_add_communitymember: permissions != undefined && permissions.includes("restapi.add_communitymember"),
    });

    super.componentDidMount(id);
  }

  onDataChanged() {
    this.clear_cache();
  }

  onMemberAdded(new_member) {
    this.clear_cache();
    this.load_page(this.state.page);
  }

  loadCommunityMembers(params) {
    return getCommunityMembers(this.props.community_pk, params);
  }

  get_data_row(data, index) {
    return (
      <CommunityMemberRow
        key={index}
        member={data}
        editMember={this.props.editMember}
        disableMember={this.props.disableMember}
        addMember={this.props.addMember}
        onMemberRemoved={this.onMemberRemoved}
        onDataChanged={this.onDataChanged}
      />
    );
  }

  render() {
    const { can_add_communitymember } = this.state;
    return (
      <>
        <nav id="nav-back" className="title col-md-10 " aria-label="breadcrumb">
          <ol className="breadcrumb">

            <li className="breadcrumb-item" onClick={this.props.goToCommunityList} >
              <Link to="#"> Retour </Link>
            </li>

            <li className="breadcrumb-item active" aria-current="page">
              {this.props.community_name}
            </li>

          </ol>
        </nav>
        <div className="col-md">
          { can_add_communitymember ? <Link to="#" className="btn btn-primary" onClick={() => { this.props.addMember(this.props.community_pk, this.props.community_name, this.onMemberAdded); }} > <ImPlus style={{ margin: '0px 0px 2px 0px' }} /> &nbsp; Créer un nouveau membre  </Link> : null }
        </div>
        {super.render()}
      </>
    );
  }
}

export default ListCommunityMembers